.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

/* ========= ENHANCED/MULTIAGENDA STYLES ========= */
.calendarAnimLeft {
  .rbc-time-view {
    transform: translate(0, 0);
    animation: mySlideLeft 0.2s ease-in-out 0s 1 alternate;
  }
}

.calendarAnimRight {
  .rbc-time-view {
    transform: translate(0, 0);
    animation: mySlideRight 0.2s ease-in-out 0s 1 alternate;
  }
}

.rbc-time-view,
.rbc-time-header-gutter,
.rbc-time-header {
  border-radius: 5px;
}

.rbc-header {
  border-bottom: none !important;
}

.rbc-event {
  font-weight: bold;
  opacity: 0.95;
}

.rbc-time-gutter {
  background: #f8f8f8;

  .rbc-label {
    font-size: 14px;
  }
}

.rbc-header.rbc-today {
  background: #f8f8f8;
}

.btn-view-agenda:focus,
.btn-view-agenda:active {
  border: none !important;
  box-shadow: none !important;
}

.rbc-event {
  z-index: 1;
}

.custom-locker:hover::before {
  content: attr(data_doctor);
  text-overflow: ellipsis;
  font-size: 12px;
}

.custom-locker {
  z-index: 1;
}

@keyframes mySlideLeft {
  0% {
    transform: translate(-5%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes mySlideRight {
  0% {
    transform: translate(5%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.time-overflow .rbc-time-header {
  padding-right: 0px !important;
}

.rbc-header {
  justify-content: center;
}

.rbc-day-slot .rbc-event {
  border-radius: 1px !important;
}

.rdt > div > input {
  max-width: 120px;
}

#configuracao-de-impressao {
  .select__control {
    height: 39px;
  }

  .p-dialog-visible {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
}
