.mb-15 {
  margin-bottom: 15px;
}

.custom-scroll {
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    margin: 8px 0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: #e7e7e7;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #727272;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #767676;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.cursor-pointer * {
  cursor: pointer;
}
