/* ========================================================================
     Component: layout
 ========================================================================== */

//
// Main layout
// This file controls the presentation of all components
//  .wrapper
//     .section-container
//       .content-wrapper
//     .aside-container
//
// If modified or removed make sure to check the variable
// shared with other components
// -------------------------------------------------------------
$aside-wd: 220px;
$aside-wd-collapsed: 70px;
$aside-wd-collapsed-text: 90px;
$aside-bg: #fff;

$content-bg: $body-bg;
$content-heading-bg: #fafbfc;
$content-heading-border: none;
$content-padding: 20px;

$footer-hg: 60px;

$navbar-hg: $navbar-height;

$boxed-max-width: 1140px;
$z-index-main-section: 111;

html {
  /* $replace rtl */
  direction: ltr;
  height: 100%; // http://updates.html5rocks.com/2013/12/300ms-tap-delay-gone-away
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  font-size: 16px; // force same font size acrros all device
}

html,
body,
#app {
  //overflow-x: hidden; Comentei pra arrumar o scroll por baixo do header, se quebrar no IOS descomenta vlw
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 100%;
}

@media print {
  #table-to-xls {
    width: 100%;
    height: 100%;
  }
}
// Main wrapper
// -----------------------------
.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden;
  &.ng-leave {
    display: none;
  }
  // Contains the main sidebar
  .aside-container {
    // visibility: hidden;
    position: absolute;
    width: $aside-wd;
    box-shadow: 10px 0px 17px 0px rgba(0, 0, 0, 0.03);

    top: 0;
    left: 0;
    bottom: 0;
    z-index: $z-index-main-section + 5; // @include transition(visibility 0s linear .3s);
    backface-visibility: hidden;
    background-color: $aside-bg;

    .aside-inner {
      padding-top: $navbar-hg;
      height: 100%;
      width: $aside-wd;
      overflow: hidden;
    }

    .nav-floating {
      // position: handled from directive
      left: inherit;
      margin-left: $aside-wd;
      z-index: $z-index-main-section * 10;

      min-width: 190px;
      overflow: auto;
    }
  }
  // Contains the main content
  .section-container {
    position: relative;
    height: 100%;
    margin-left: 0; // overflow: hidden;
    z-index: $z-index-main-section;
    background-color: $content-bg;
    margin-bottom: $footer-hg !important; // ensure a space for the footer
  } // Page Footer
  .footer-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: $footer-hg;
    border-top: 1px solid $gray-light;
    padding: $content-padding;
    z-index: $z-index-main-section - 2;
    font-size: 0.875rem;
    p {
      margin: 0;
    }
  }
}

.modal-open .wrapper {
  z-index: 0;
}

// Page main content
// -----------------------------
.content-wrapper {
  padding: 15px;
  width: 100%; // styling to visually wrap the component
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px;

  .unwrap {
    margin: -15px; // expand over wrapper padding
    @include media-breakpoint-up(md) {
      margin: -20px; // expand over wrapper padding
    }
  }

  .content-heading {
    display: block;
    align-items: center;
    font-size: 1.5rem;
    line-height: $headings-line-height;
    color: #7d7d7d;
    margin: -15px;
    margin-bottom: 10px;
    padding: 15px;
    font-weight: bold;
    background-color: $content-heading-bg;
    border-bottom: 1px solid $content-heading-border;
    small {
      display: block;
      font-size: 12px;
      color: #7d7d7d;
    }
  }

  .container,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-up(md) {
    padding: $content-padding; //overflow: auto; // required for portlets
    .content-heading {
      margin: -$content-padding;
      margin-bottom: 10px;
      padding: $content-padding;
      padding-top: 30px;
      button,
      .btn {
        margin: 0;
      }
    }
  }
}

// Desktop layout
// -----------------------------
@include media-breakpoint-up(md) {
  body {
    min-height: 100%;
  }

  .wrapper {
    .aside-container {
      .aside-inner {
        padding-top: $navbar-hg;
      }
    }

    .section-container,
    .footer-container {
      margin-left: $aside-wd;
    }

    .section-container {
      // with just a margin we can make the
      // right sidebar always visible
      &.has-sidebar-right {
        margin-right: $aside-wd + 20;
        + .offsidebar {
          z-index: 1;
        }
      }
    }
  }
}

// Aside toggled layout
// On mobile acts like offcanvas
// -----------------------------
@include media-breakpoint-down(sm) {
  .wrapper {
    .aside-container {
      margin-left: -$aside-wd;
    }
  }
  .aside-toggled {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd;
      }

      .aside-container {
        margin-left: 0;
      }
    }
  }

  .csstransforms3d {
    .wrapper {
      backface-visibility: hidden;
      .section-container,
      .footer-container {
        margin-left: 0;
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease;
      }
      .aside-container {
        margin-left: 0;
        transform: translate3d(-$aside-wd, 0, 0);
        transition: transform 0.3s ease;
      }
    }

    .aside-toggled {
      .wrapper {
        .section-container,
        .footer-container {
          transform: translate3d($aside-wd, 0, 0);
        }
        .aside-container {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

// Toggle and collapsed behavior overrides
@include media-breakpoint-down(sm) {
  .aside-collapsed .wrapper {
    .aside-container {
      margin-left: -$aside-wd-collapsed;
    }
  }

  .aside-collapsed.aside-toggled {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd-collapsed;
      }

      .aside-container {
        margin-left: 0;
      }
    }
  }

  .csstransforms3d {
    .aside-collapsed .wrapper {
      backface-visibility: hidden;
      .section-container,
      .footer-container {
        margin-left: 0;
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease;
      }
      .aside-container {
        margin-left: 0;
        transform: translate3d(-$aside-wd-collapsed, 0, 0);
        transition: transform 0.3s ease;
      }
    }

    .aside-collapsed.aside-toggled {
      .wrapper {
        .section-container,
        .footer-container {
          transform: translate3d($aside-wd-collapsed, 0, 0);
        }
        .aside-container {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

// Aside collapsed layout
// -------------------------------
// Aside status toggled via JS
.aside-collapsed {
  overflow-y: auto;
  .wrapper {
    .aside-container {
      &,
      .aside-inner {
        width: $aside-wd-collapsed;
      }
      .nav-floating {
        margin-left: $aside-wd-collapsed;
      }
    }
  }
}

// Margin only exists above tablet
@include media-breakpoint-up(md) {
  // Aside status toggled via JS
  .aside-collapsed {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd-collapsed;
      }
    }
  }
}

// ------------------------------
// Collapsed variation with text
// ------------------------------
@include media-breakpoint-down(sm) {
  .aside-collapsed-text .wrapper {
    .aside-container {
      margin-left: -$aside-wd-collapsed-text;
    }
  }

  .aside-collapsed-text.aside-toggled {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd-collapsed-text;
      }

      .aside-container {
        margin-left: 0;
      }
    }
  }

  .csstransforms3d {
    .aside-collapsed-text .wrapper {
      backface-visibility: hidden;
      .section-container,
      .footer-container {
        margin-left: 0;
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease;
      }
      .aside-container {
        margin-left: 0;
        transform: translate3d(-$aside-wd-collapsed-text, 0, 0);
        transition: transform 0.3s ease;
      }
    }

    .aside-collapsed-text.aside-toggled {
      .wrapper {
        .section-container,
        .footer-container {
          transform: translate3d($aside-wd-collapsed-text, 0, 0);
        }
        .aside-container {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

// Aside collapsed layout
// -------------------------------
// Aside status toggled via JS
.aside-collapsed-text {
  overflow-y: auto;
  .wrapper {
    .aside-container {
      &,
      .aside-inner {
        width: $aside-wd-collapsed-text;
      }
      .nav-floating {
        margin-left: $aside-wd-collapsed-text;
      }
    }
  }
}

// Margin only exists above tablet
@include media-breakpoint-up(md) {
  // Aside status toggled via JS
  .aside-collapsed-text {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd-collapsed-text;
      }
    }
  }
}

// end collapsed variation with text
// mixin to remove transformations
@mixin disable-transform() {
  -webkit-transform: none;
  -moz-transform: none;
  -opera-transform: none;
  -ms-transform: none;
  transform: none;
}

// Fixed layout
// -----------------------------
.layout-fixed {
  @media (max-width: $boxed-max-width) {
    &.aside-toggled,
    &.offsidebar-open {
      overflow-y: hidden;
    }
  }

  .wrapper {
    .topnavbar-wrapper {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $z-index-main-section + 10;
    }
    .aside-container,
    .offsidebar {
      position: fixed;
    }
    /* only applied to sidebar */
    .aside-container {
      /* safari fix */
      height: 1px;
      min-height: 100%;
      /* ios blanks space fix */
      .aside-inner {
        position: fixed;
        top: 0;
        bottom: 0;
      }
    }
    .section-container {
      margin-top: $navbar-hg;
    }
  }
}

/* IE10+ hack: safari fix breaks ie so we need to target ie only to restore */

_:-ms-lang(x),
.layout-fixed .wrapper .aside-container .aside-inner {
  position: static !important;
}

// Boxed layout (only for desktops)
// -----------------------------
.layout-boxed {
  @include media-breakpoint-up(lg) {
    overflow: auto !important;
    .wrapper {
      margin: 0 auto;
      overflow: hidden;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);

      .offsidebar {
        position: absolute !important;
      }

      .aside-container {
        left: inherit;
      }
      &,
      & .topnavbar-wrapper {
        width: 970px;
      }
    }

    &.layout-fixed {
      .wrapper .aside-container .aside-inner {
        left: inherit;
      }
    }
  } // max size for large devices
  @include media-breakpoint-up(xl) {
    .wrapper {
      &,
      & .topnavbar-wrapper {
        width: $boxed-max-width;
      }
    }
  }
} // boxed

// Sidebar backdrop
.sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: $aside-wd;
  z-index: $z-index-main-section + 1;
  .aside-collapsed & {
    left: $aside-wd-collapsed;
  }
  .aside-collapsed-text & {
    left: $aside-wd-collapsed-text;
  }
}
.react-datepicker__month-container {
  border: 0 !important;
}
.datepicker-custom {
  border: 1px solid #e4eaec !important;
}
.react-datepicker__month-select {
  background-color: white;
  color: rgb(65, 65, 65);
  font-weight: bold;
}
.react-datepicker__year-select {
  background-color: white;
  color: rgb(65, 65, 65);
  font-weight: bold;
}
.react-datepicker__current-month {
  display: none;
}
.react-datepicker__day-names {
  border-bottom: 0 !important;
}

.react-datepicker__header {
  background-color: #ffffff !important;
  border-bottom: 0 !important;
}

.react-datepicker__day-name {
  color: #666666 !important;
  text-transform: capitalize;
  font-weight: 700 !important;
}

.react-datepicker__current-month {
  color: #666666 !important;
}

//Color datepicker
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-rang {
  background-color: #73b7eb;
}
.react-datepicker__day--selected {
  background-color: #106db5;
}
//Fix Bootstrap tabs
.nav-link {
  cursor: pointer !important;
}
.rbc-event-content {
  font-size: 12px;
}
//Fix logo mark tinymce
span {
  &.mce-last {
    display: none !important;
  }
}
// Fix tooltips hover(piscando)
.tooltip {
  pointer-events: none;
}

.rodape-fixado {
  position: fixed;
  height: 60px;
  width: 100%;
  z-index: 200;

  @media (max-width: 600px) {
    height: 90px;
  }
}
.table-filter-icon {
  cursor: pointer;
}
//Custom layout datepickers da tabela
.datepicker-custom {
  display: grid;
}
//Custom style mobile for tinymce
.mce-toolbar .mce-btn {
  display: table-row;
  float: left;
}
.mce-btn {
  border: 1px solid #dcdcdc !important;
  border-radius: 10% !important;
  padding: 0 !important;
}
.mce-btn:hover {
  background-color: aliceblue !important;
}
// .mce-txt {
//   font-size: 10px !important;
//   color: black !important;
// }
@media screen and (max-width: 515px) {
  // modal info agendamento
  .bottom_modal--agenda {
    padding-bottom: 70px;
  }
  .mce-window {
    max-width: 320px !important;
    left: calc(50% - 160px) !important;
  }
  .mce-window-body {
    max-width: 100% !important;
    min-height: 230px !important;
  }
  .mce-container.mce-panel.mce-abs-layout-item,
  .mce-container.mce-panel.mce-abs-layout-item .mce-container-body.mce-abs-layout {
    max-width: 100% !important;
    min-height: 190px !important;
  }
  .mce-flow-layout {
    text-align: center !important;
  }
  .mce-flow-layout-item.mce-btn-group {
    border-left: none !important;
  }
  .mce-panel,
  .mce-panel > .mce-container-body,
  .mce-foot,
  .mce-foot > .mce-abs-layout {
    max-width: 320px !important;
    text-align: center;
  }
  .mce-formitem {
    width: 300px !important;
    left: 10px !important;
    display: block !important;
  }
  .mce-formitem:nth-child(2) {
    top: 10px !important;
  }
  .mce-formitem:nth-child(3) {
    top: 50px !important;
  }
  .mce-formitem:nth-child(4) {
    top: 90px !important;
  }
  .mce-formitem:nth-child(5) {
    top: 130px !important;
  }
  .mce-formitem:nth-child(6) {
    top: 170px !important;
  }
  .mce-container.mce-form.mce-abs-layout-item
    .mce-container-body.mce-abs-layout
    .mce-container.mce-form.mce-abs-layout-item {
    top: 40px !important;
    left: 0 !important;
    height: 160px !important;
  }
  .mce-formitem label {
    width: 106px !important;
    font-size: 13px !important;
    left: 0px !important;
  }
  .mce-multiline {
    max-width: 290px !important;
  }
  .mce-checkbox {
    top: 40px !important;
    left: 0 !important;
  }
  .mce-label {
    font-size: 13px !important;
  }
  .mce-foot .mce-btn-has-text {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    display: inline-block !important;
    margin: 10px 5px !important;
  }
  .mce-abs-layout-item input {
    max-width: 150px !important;
  }
  .mce-abs-layout-item.mce-has-open input {
    max-width: 118px !important;
  }
}
.switch {
  margin-left: 5px !important;
}
// Icones barra do header
@media (max-width: 576px) {
  .fa-responsive {
    font-size: x-small;
  }
}
//forçar letra maiuscula no header do popover
.popover-header {
  text-transform: capitalize;
}
//Linha do tempo correção em telas grandes
.vertical-timeline {
  max-width: inherit !important;
}
// Input file  correção para não quebrar o navegador
.filepond--drop-label > label {
  cursor: pointer !important;
  display: none;
}
.show > .filepond--drop-label {
  display: block;
}
.datePickerInput {
  display: none;
}
.table-filter-parent {
  border: 0 !important;
}
.rdt {
  width: 100%;
}
.drop-preview {
  background-color: #136cdc;
}

//correção linha agenda
.rbc-current-time-indicator {
  background-color: red !important;
  width: inherit;
}

//Selects com botões helper class
@media (min-width: 500px) {
  .select-button {
    padding-right: 0;
    margin-right: 0;
  }
  .button-select {
    margin-left: 0;
    padding-left: 0;
  }
}
@media (max-width: 500px) {
  .select-button {
    width: 80%;
  }
  .button-select {
    display: contents;
  }
}
.rte-autocomplete {
  text-align: center;
}

.rte-autocomplete > li.active {
  background-color: rgb(38, 146, 247);
  a {
    color: white !important;
  }
}

//layout dropdown header

.droppdown-overflow {
  max-height: 200px;
  overflow-y: auto;
}

//Regra remoção footer caixas de texto
.mce-statusbar .mce-container-body {
  display: none;
}
.rbc-events-container {
  cursor: pointer !important;
}
.calendar-ds
  > .rbc-time-view
  > .rbc-time-content
  > .rbc-time-gutter
  > .rbc-timeslot-group:last-child
  > .rbc-time-slot
  > .rbc-label {
  display: none;
}
.rbc-event {
  border-width: "2px";
  border-radius: 3px !important;
}

.time-overflow {
  .rbc-time-header {
    padding-right: 15px !important;
    margin-right: 0 !important;
  }
}
.rbc-time-header,
.rbc-time-header-cell {
  background: #f8f8f8;
}
.rbc-event {
  filter: brightness(105%);
}
.rbc-event:hover {
  z-index: 9999;
  filter: grayscale(20%);
}
#sb_widget > .widget {
  height: 40px !important;
  width: 40px !important;
}
.mce-tinymce {
  box-shadow: none !important;
}
.mce-panel {
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
// linha do temṕo atendimento
.force-one-row > .row {
  display: inline-block;
  margin-top: 8px;
}
.force-one-row > .row > .col-md-4 {
  flex: 0 0 100%;

  max-width: 100%;
}
.force-one-row > .row > .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%;
}
//prontuarios modelos
.background-form-prontuario {
  background-color: #e3e3e3;
  padding: 10px;
}
//linha do tempo paciente
.data--consulta .vertical-timeline-element-content {
  display: none !important;
}
.consulta-compartilhado .vertical-timeline-element-content {
  display: none !important;
}
.eu_videochamada {
  position: absolute;
  bottom: 0%;
  right: 2%;
}
.eu_videochamada--video {
  height: 128px;
  width: 128px;
}
.outro_videochamada {
  margin-top: 8px;
  height: 400px;
}
.outro_videochamada--video {
  max-height: 80vh;
}
.local-participant {
  margin-top: 8px;
}
.info_medico {
  position: absolute;
  padding: 10px;
  top: 0%;
  left: 88%;
}
.video-chat--buttons--medico {
  position: absolute;
  top: 86%;
  left: 40%;
}
.video-chat--buttons--paciente {
  position: absolute;
  top: 86%;
  left: 45%;
}
//borda campos vazios
.empty-field {
  border: 2px solid #f05050 !important;
  transition: border-color 0.4s linear;
}
.deafult-field {
  border: 1px solid gray;
}

@media (max-width: 520px) {
  .video-chat--buttons--medico,
  .video-chat--buttons--paciente {
    position: relative;
    display: block;
    top: auto;
    left: auto;
    margin-bottom: 8px;
  }
}
//borda campos vazios
.empty-field {
  border: 2px solid #f05050 !important;
  transition: border-color 0.4s linear;
}
// borda mais larga 2 px
.border-size--md {
  border-right: 1px solid #dde6e9;
}

.blur_block_element {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.month-picker .rdtPicker {
  right: 0;
}
